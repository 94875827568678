import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "48",
  height: "42",
  viewBox: "0 0 48 42",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M12.7991 0.613403C17.7729 0.613403 22.0662 3.58453 24 7.84381V41.3866L4.85344 22.2912C4.35891 21.8726 3.90157 21.4168 3.47983 20.9263C2.49157 19.7798 1.71219 18.4505 1.19788 16.9946C0.745286 15.7159 0.5 14.3439 0.5 12.9133C0.5 6.13155 6.01578 0.613403 12.7991 0.613403Z",
      fill: "#FF7602"
    }, null, -1),
    _createElementVNode("path", {
      d: "M35.2009 0.613403C30.2271 0.613403 25.9338 3.58453 24 7.84381V41.3866L43.1466 22.2912C43.6411 21.8726 44.0984 21.4168 44.5202 20.9263C45.5084 19.7798 46.2878 18.4505 46.8021 16.9946C47.2547 15.7159 47.5 14.3439 47.5 12.9133C47.5 6.13155 41.9842 0.613403 35.2009 0.613403Z",
      fill: "#212529"
    }, null, -1)
  ])))
}
export default { render: render }